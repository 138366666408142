$(document).ready(function() {
  var current_page = 0, current_url = "", current_asset = "";
  $('[data-class="preview-asset"]').click(function(e) {
    current_page = 0;
    current_url = $(this).data("url");
    current_asset = $(this).data("id");
    $("#clear_preview").trigger("click");
    call_preview(current_url, current_page, current_asset);
  });
  $("#clear_preview, .overlay").click(function() {
    $(".overlay").toggle();
    $(".preview_container").toggle();
  });
  $("#prev_preview").click(function() {
    current_page--;
    call_preview(current_url, current_page, current_asset);
  });
  $("#next_preview").click(function() {
    current_page++;
    call_preview(current_url, current_page, current_asset);
  });
});
function format_nav(prev, next, index) {
  $("#prev_preview").hide();
  $("#next_preview").hide();
  if (next) {
    $("#next_preview").show();
  }
  if (prev) {
    $("#prev_preview").show();
  }
}
function call_preview(a_url, index, element_id) {
  $(".loading-container").show();
  $(".preview-image").hide();
  $.ajax({
    url: a_url,
    data: { index },
    success: function(res) {
      if (res.success) {
        $("#" + element_id).remove();
        var assetContainer = $("<div>", { id: element_id }).appendTo(".images-container");
        res.data.forEach(function(imgSrc, imgIdx) {
          var image = $("<img>", {
            src: imgSrc,
            class: "preview-image",
            "data-index": index + "-" + imgIdx
          }).appendTo(assetContainer);
          image.on("load", function() {
            $(".loading-container").hide();
          });
        });
        format_nav(res.prev, res.next, index);
      } else {
        $(".loading-container").hide();
        alert(res.errors);
      }
    },
    error: function() {
      $(".loading-container").hide();
    }
  });
}
function find_image(element_id, idx) {
  return $(".images-container").find(
    "#" + element_id + " img[data-index=" + idx + "]"
  );
}
function create_node(element_id, idx) {
  var assetContainer = $("#" + element_id);
  if (assetContainer.length) {
    assetContainer.append(
      "<img src='' class='preview-image' data-index=" + idx + " />"
    );
  } else {
    $(".images-container").append(
      "<div id=" + element_id + "><img src='' class='preview-image' data-index=" + idx + " /></div>"
    );
  }
  return find_image(element_id, idx);
}
